import React, {useState, useEffect, useMemo, useContext} from 'react';

import {useHistory, useParams} from 'react-router-dom';
import {pathOr, propOr} from 'ramda';
import {useFlag} from '@unleash/proxy-client-react';
import {context as ProjectTasksContext} from 'lib/projectTasks';

import {
  FixedDrawer,
  CollapsedMenu,
  Menu as StyledMenu,
  RenoFiDesignCard,
} from '@renofi/components-internal';
import SignOut from '@renofi/icons/src/SignOut';
import {useIsImpersonation, useLogout} from '@renofi/utilities/src/hooks';
import {isMobile, mobile} from '@renofi/theme/src/breakpoints';
import {useToggledBorrowerProject, useLenderByCrmId} from '@renofi/graphql';

import {getSideMenu} from './utils';
import {
  StyledContainer,
  StyledHeader,
  StyledSignOut,
  SignOutIcon,
  SignOutLabel,
} from './Drawer.styles';

const LENDER_PATH = ['renovationUnderwritingRequest', 'lender'];

const Drawer = () => {
  const history = useHistory();
  const {projectId} = useParams();
  const impersonation = useIsImpersonation();

  const {project: currentProject, projects} = useToggledBorrowerProject();
  const {fetch: fetchLenderByCrmId} = useLenderByCrmId({lazy: true});

  const {outstanding} = useContext(ProjectTasksContext);
  const [lender, setLender] = useState(null);
  const [open, setOpenDrawer] = useState(false);
  const {logout} = useLogout();
  const isRaas = propOr(false, 'raas', currentProject);
  const isRenoFiDesignEnabled = useFlag('secure-renofi-design');

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const onOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const onSelectStep = (step) => {
    history.push(`/${projectId}/${step.id}`);
    if (isMobile()) {
      onCloseDrawer();
    }
  };

  const onResizeWindow = () => {
    const {clientWidth = 0} = document.body;
    setOpenDrawer(clientWidth > mobile);
  };

  const menu = useMemo(() => {
    return getSideMenu(outstanding, {isRaas, isRenoFiDesignEnabled});
  }, [outstanding, isRaas, isRenoFiDesignEnabled]);

  useEffect(() => {
    (async () => {
      if (!isRaas) {
        return setLender(null);
      }

      const handleLenderByCrmId = async () => {
        const result = await fetchLenderByCrmId({
          variables: {
            crmId: currentProject.matchedToLenderCrmId,
          },
        });
        const lenderByCrmId = pathOr(null, ['data', 'lenderByCrmId'], result);
        setLender(lenderByCrmId);
      };

      const currentLender = pathOr(null, LENDER_PATH, currentProject);

      if (!currentLender && currentProject?.matchedToLenderCrmId) {
        handleLenderByCrmId();
        return;
      }

      setLender(currentLender);
    })();
  }, [isRaas, JSON.stringify(currentProject)]);

  useEffect(() => {
    window.addEventListener('resize', onResizeWindow);
    onResizeWindow();

    return () => window.removeEventListener('resize', onResizeWindow);
  }, []);

  const onProjectChange = (selectedProject) => {
    history.push(`/${selectedProject.id}`);
  };

  const menuProjects = useMemo(() => {
    return projects.toSorted((left, right) => {
      return new Date(right?.createdAt) - new Date(left?.createdAt);
    });
  }, [JSON.stringify(projects)]);

  return (
    <FixedDrawer
      lender={lender}
      open={open}
      onClose={onCloseDrawer}
      onOpen={onOpenDrawer}
      top={impersonation ? 60 : 0}>
      <StyledContainer>
        <StyledHeader>
          {menuProjects?.length > 0 ? (
            <CollapsedMenu
              current={currentProject}
              data={menuProjects}
              onSelectItem={onProjectChange}
            />
          ) : null}
        </StyledHeader>
        <StyledMenu data={menu} onSelect={onSelectStep} />
        {!isMobile() && isRenoFiDesignEnabled && <RenoFiDesignCard />}
      </StyledContainer>
      <StyledSignOut onClick={logout}>
        <SignOutIcon>
          <SignOut />
        </SignOutIcon>
        <SignOutLabel>Sign out</SignOutLabel>
      </StyledSignOut>
    </FixedDrawer>
  );
};

export default Drawer;
