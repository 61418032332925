import React, {useState, useEffect, useCallback, useContext} from 'react';

import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import lazy from 'lib/asyncComponent';
import {Help, StickyHeader} from 'modules/layout';

import {Flex, Loader, TaskCard} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import {useToggledBorrowerProject, useGetUser} from '@renofi/graphql';

import {CardsContainer, StatusContainer, StyledContainer} from './styled';
import {
  getTasksInfo,
  getWelcomeStatusContent,
  getCompletedStatusContent,
  getNewTasksStatusContent,
} from './utils';
import {TASK_ID_KEY} from './constants';

const Nothing = lazy(
  () => import(/* webpackChunkName: "no-checklist" */ '../Nothing'),
);

const Project = () => {
  const history = useHistory();
  const storage = useContext(StorageContext);
  const {user} = useGetUser();

  const {project, loading: loadingBorrowerProject} =
    useToggledBorrowerProject();

  const {
    allTasks,
    eligibility = [],
    loading,
    ...tasks
  } = useContext(ProjectTasksContext);

  const [scrollShadow, setScrollShadow] = useState(false);
  const isRaas = propOr(false, 'raas', project);
  const hasCoBorrower = propOr(
    false,
    'coborrowerIsPresent',
    eligibility[0]?.borrowersAndPropertyInformation,
  );

  const {
    tasksInfo,
    allTasksCount,
    outstandingTasksCount,
    hasNewTaskAfterCompletion,
  } = getTasksInfo({
    ...tasks,
    eligibility,
    isRaas,
    hasCoBorrower,
  });

  const projectId = project?.id;
  const hasTasks = Boolean(allTasks?.length);

  const isAllTasksCompleted = hasTasks && outstandingTasksCount === 0;
  const isNoneTasksCompleted = outstandingTasksCount >= allTasksCount;

  const viewTask = (task) => {
    history.push(`/${projectId}/tasks/${task.id}`);
  };

  const handleScroll = useCallback(() => {
    const value = window.pageYOffset;
    if (value > 30 && !scrollShadow) {
      setScrollShadow(true);
    } else if (value < 30) {
      setScrollShadow(false);
    }
  }, [window.pageYOffset, scrollShadow]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const firstUploadTaskId = storage.getItem(TASK_ID_KEY);
    const hasOneDocument = project?.documents?.length === 1;

    if (firstUploadTaskId && hasOneDocument) {
      const {documentType, fileName} = project?.documents[0];
      sendEvent('Secure/One-Document-Uploaded', {
        name: `${documentType} - ${fileName}`,
      });
    }
  }, [projectId]);

  if (loading || loadingBorrowerProject) {
    return <Loader />;
  }

  if (!projectId) {
    return <Nothing />;
  }

  return (
    <>
      <Flex justifyContent="center" width="100%">
        <StyledContainer narrow>
          <StickyHeader backButton={false} title="Tasks" />
          <>
            <StatusContainer>
              {isNoneTasksCompleted && (
                <TaskCard
                  data={getWelcomeStatusContent(user, isRaas)}
                  statusCard
                />
              )}

              {isAllTasksCompleted && (
                <TaskCard data={getCompletedStatusContent(user)} statusCard />
              )}

              {hasNewTaskAfterCompletion && !isNoneTasksCompleted && (
                <TaskCard
                  data={getNewTasksStatusContent(user, outstandingTasksCount)}
                  statusCard
                />
              )}
            </StatusContainer>
            <CardsContainer>
              {tasksInfo.map(
                (category, key) =>
                  category.tasksLength > 0 && (
                    <TaskCard
                      key={key}
                      data={category}
                      onClickView={viewTask}
                    />
                  ),
              )}
            </CardsContainer>
          </>
          <Help />
        </StyledContainer>
      </Flex>
    </>
  );
};

Project.propTypes = {
  consecutiveTaskIds: PropTypes.arrayOf(PropTypes.string),

  user: PropTypes.object,
  storage: PropTypes.object,
};

export default React.memo(Project);
