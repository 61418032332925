import {find, pipe, propEq, propOr} from 'ramda';

import {isTpoProcess} from '../application-process';

export const getProductDetailsConfirmation = pipe(
  propOr([], 'tasks'),
  find(propEq('taskType', 'product_details_confirmation')),
  propOr(null, 'productDetailsConfirmation'),
);

const getApplicationProcess = pipe(
  getProductDetailsConfirmation,
  propOr('', 'applicationProcess'),
);

export const isTpoApplication = (project = {}) => {
  const applicationProcess = getApplicationProcess(project);

  return isTpoProcess(applicationProcess);
};

export default isTpoApplication;
