import {useEffect} from 'react';

import {getFullName} from '@renofi/utilities/src/data';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {showSurvey} from '@renofi/analytics';

const Delighted = () => {
  const {project} = useToggledBorrowerProject();
  const {borrowersAndPropertyInformation: propertyInfo, id} = project || {};

  useEffect(() => {
    if (propertyInfo?.applicantSubmittedAt) {
      showSurvey({
        id: id,
        email: project.borrower?.email,
        name: getFullName(propertyInfo),
        properties: {
          Stage: project.stageName,
          'Uploaded Documents Count': project.documents?.length,
          'Loan Type': project.loanType,
        },
      });
    }
  }, [propertyInfo?.applicantSubmittedAt]);

  return null;
};

export default Delighted;
