import React from 'react';

import PropTypes from 'prop-types';
import ManageDocuments from 'modules/tasks-wizard/components/ManageDocuments';

import {Alert} from '@renofi/components-internal';
import {useSubmitProjectDocument} from '@renofi/graphql';

import {Content, Title} from '../styled';

const InsurancePolicyStep = ({
  task,
  address,
  realEstateOwnedPropertyId,
  documents,
  isRenofiLoan = false,
  isSubjectProperty = false,
  onUploadComplete,
}) => {
  const {submitProjectDocument} = useSubmitProjectDocument();
  const {id: taskId, facet} = task;

  return (
    <Content>
      <Title>
        Now, upload the <strong>homeowners insurance policy</strong> for{' '}
        {address}.
      </Title>

      {isRenofiLoan && isSubjectProperty ? (
        <Alert mb={24} variant="info">
          Most insurance companies will provide this immediately after
          purchasing your policy. They can also provide additional confirmation
          upon request. Depending on the complexity of the renovation we might
          ask you to provide proof of future dwelling coverage in a separate
          task. In the meantime, please inform your insurance carrier that
          you’re doing a renovation as that is a prerequisite for applying for
          the loan with Lender.
        </Alert>
      ) : null}

      <ManageDocuments
        forceRemoveButton
        customDocuments={documents}
        taskId={taskId}
        facet={facet}
        fileName="homeowners insurance policy"
        customMutation={submitProjectDocument}
        customResponsePath="submitProjectDocument"
        customMutationParams={{
          realEstateOwnedPropertyId,
          documentType: 'homeowners_insurance_policy',
          attachedRecordTarget: {
            id: realEstateOwnedPropertyId,
            type: 'real_estate_owned_property',
          },
        }}
        onUploadComplete={onUploadComplete}
      />
    </Content>
  );
};

InsurancePolicyStep.propTypes = {
  task: PropTypes.object,
  address: PropTypes.string,
  realEstateOwnedPropertyId: PropTypes.string,
  documents: PropTypes.array,
  onUploadComplete: PropTypes.func,
};

export default InsurancePolicyStep;
