import isImpersonation from '@renofi/utilities/src/isImpersonation';
import googlePlaces from '@renofi/utilities/src/googlePlaces';
import {
  initAccessibe,
  initDataDog,
  initDelighted,
  initLogRocket,
  initSegment,
  initSentry,
  resetAnalytics,
} from '@renofi/analytics';

export default async ({config, storage}) => {
  const {DEVELOPMENT, GOOGLE_MAPS_API_KEY, PRODUCTION} = config;
  const impersonation = isImpersonation(storage);

  if (GOOGLE_MAPS_API_KEY) {
    googlePlaces(GOOGLE_MAPS_API_KEY);
  }

  window.addEventListener('beforeunload', () => {
    resetAnalytics();
  });

  if (impersonation) return;

  if (!DEVELOPMENT) {
    initSegment({
      integrations: {
        All: true,
        'Facebook Pixel': false,
        'Bing Ads': false,
        AutopilotHQ: false,
        'AdWords Remarketing Lists': false,
      },
    });
  }

  if (PRODUCTION) {
    initLogRocket({sanitize: true});
    initSentry();
    initAccessibe();
    initDelighted('aorg1wFPNDtVus7t', 'delightedNps');
  }

  initDataDog({envs: ['staging']});
};
