export default function useTogglePropertySteps({
  isPaymentsEnabled,
  isSubjectProperty,
} = {}) {
  return (toggleStep, route, property) => {
    const {
      existingMortgageLoan,
      homeownersAssociationFee, // Float
      homeownersAssociationFeesIncluded,
      homeownersAssociationFeesExist,
      propertyMonthlyTaxes, // Float
      taxAndInsuranceIncluded,
      isLandLoan,
    } = property || {};
    const hasNoMortgage = isPaymentsEnabled
      ? !existingMortgageLoan
      : existingMortgageLoan === false;

    toggleStep(
      `${route}/mortgage-statement`,
      existingMortgageLoan === undefined || existingMortgageLoan === true,
    );
    toggleStep(
      `${route}/insurance`,
      (isSubjectProperty && !isLandLoan) ||
        (!isSubjectProperty &&
          (taxAndInsuranceIncluded === false || hasNoMortgage)),
    );
    toggleStep(
      `${route}/tax`,
      propertyMonthlyTaxes ||
        taxAndInsuranceIncluded === false ||
        hasNoMortgage,
    );
    toggleStep(
      `${route}/fees`,
      Boolean(
        homeownersAssociationFee ||
          (homeownersAssociationFeesExist === true &&
            homeownersAssociationFeesIncluded === false),
      ),
    );
  };
}
