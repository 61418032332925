import {useApolloClient} from '@apollo/client';

import logger from '@renofi/utilities/src/logger';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums';
import useChannel from '@renofi/utilities/src/pusher/hooks/useChannel';

import {GET_PROJECT_TASKS} from '../../queries';

export default function useDocumentStatusUpdated(projectId, documentStatus) {
  const client = useApolloClient();

  const onDocumentStatusChanged = async (event) => {
    logger.debug('DOCUMENT_STATUS_CHANGED', documentStatus, event);
    const {document_id: documentId, task = {}} = event || {};
    const {id: taskId} = task;

    if (!projectId || !documentId) {
      return;
    }

    const {projectTasks = []} = client.readQuery({
      query: GET_PROJECT_TASKS,
      variables: {projectId},
    });

    client.writeQuery({
      query: GET_PROJECT_TASKS,
      variables: {projectId},
      data: {
        projectTasks: projectTasks.map((task) => {
          if (task.id !== taskId) {
            return task;
          }
          const documents = (task?.documents || []).reduce((arr, doc) => {
            if (doc.id !== documentId) {
              return arr.concat(doc);
            }
            return arr.concat({
              ...doc,
              status: taskDocumentStatuses[documentStatus],
            });
          }, []);

          return {...task, documents};
        }),
      },
    });
  };

  useChannel({
    channelName:
      projectId && `document_${documentStatus}--project=${projectId}`,
    onUpdate: onDocumentStatusChanged,
  });

  return {};
}
