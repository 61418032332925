import {useContext} from 'react';

import {both, find, isNil, pipe, propOr} from 'ramda';

import {useToggledBorrowerProject, useGetUser} from '@renofi/graphql';
import {Context as ConfigContext} from '@renofi/utilities/src/config';

const DEFAULT_NAME = 'Ruben Tang';

const isNotNil = (key) => pipe(propOr(null, key), (v) => !isNil(v));

const findOwner = find(both(isNotNil('name'), isNotNil('email')));

export default function useProjectOwner() {
  const {user} = useGetUser();
  const config = useContext(ConfigContext);
  const {project} = useToggledBorrowerProject();

  const renofiAdvisor = propOr(null, 'renofiAdvisor', project);
  const projectAssociate = propOr(null, 'projectAssociate', project);
  const fallback = {
    name: DEFAULT_NAME,
    email: config.RENOFI_CONTACT_EMAIL,
  };

  const target = findOwner([renofiAdvisor, projectAssociate, fallback]);

  return {
    owner: target,
    project,
    user,
  };
}
