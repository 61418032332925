import React from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Flex,
  SimpleDateField,
  SelectField,
  Text,
} from '@renofi/components-internal';
import {realEstateOwnedPropertyTypes} from '@renofi/utilities/src/enumTypes';

import {Content, Title} from '../styled';
import ApplicantsSelect from '../ApplicantsSelect';

const PLACEHOLDER = 'MM/YYYY';
const MASK = PLACEHOLDER.replace(/\//gi, '').split('');

const SoldPropertyDetailsStep = ({onChange, property, borrowersInfo}) => {
  const {
    propertyStructureType,
    soldDate,
    streetAddressOne,
    coborrowerOwned,
    primaryBorrowerOwned,
  } = property || {};

  return (
    <Content>
      <Title>
        Now, provide <strong>details</strong> for the property sold at{' '}
        {streetAddressOne}.
      </Title>

      <Flex>
        <Box flex={1} mr={3}>
          <SelectField
            name="propertyStructureType"
            value={propertyStructureType}
            label="Property type"
            options={realEstateOwnedPropertyTypes}
            onChange={(value) => onChange({propertyStructureType: value})}
          />
        </Box>

        <Box flex={1}>
          <SimpleDateField
            mask={MASK}
            name="soldDate"
            placeholder={PLACEHOLDER}
            value={soldDate}
            label="Date sold"
            onChange={(value, isValid) => onChange({soldDate: value}, isValid)}
          />
        </Box>
      </Flex>

      {borrowersInfo?.coborrowerIsPresent ? (
        <>
          <Text fontSize={20} mb={16}>
            Ownership
          </Text>
          <ApplicantsSelect
            onChange={onChange}
            coborrowerOwned={coborrowerOwned}
            primaryBorrowerOwned={primaryBorrowerOwned}
            borrowersInfo={borrowersInfo}
          />
        </>
      ) : null}
    </Content>
  );
};

SoldPropertyDetailsStep.propTypes = {
  property: PropTypes.object,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  borrowersInfo: PropTypes.object,
};

export default SoldPropertyDetailsStep;
