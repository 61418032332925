import React, {useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, pathOr} from 'ramda';
import {useCurrentTask} from 'modules/project/hooks';
import {
  STATUS_OUTSTANDING,
  STATUS_REJECTED,
} from 'modules/project/components/Project/constants';

import {
  useMarkTaskAsOutstanding,
  useSubmitGovInformation,
  useToggledBorrowerProject,
} from '@renofi/graphql';
import {sendEvent} from '@renofi/analytics';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {ConfirmCloseTaskModal} from '@renofi/components-internal';

import {
  prepareDataToSubmit,
  getWizardConfig,
  getBorrowerDetails,
} from './utils';
import {
  STEP_TYPES,
  BORROWERS_TYPES,
  CO_BORROWER_STEP_TYPES,
  GOV_ID_EVENTS,
  WIZARD_ID_TYPES,
  FAILED_NOTIFICATION,
} from './constants';
import Wizard from './components/Wizard';
import Borrower from './components/Borrower';

const GovIdWizard = ({taskId, onClose, ...props}) => {
  const {task} = useCurrentTask({taskId, facet: 'eligibility'});
  const {project} = useToggledBorrowerProject();
  const {
    documents = [],
    governmentIssuedIds: govIdDetails = [],
    status: taskStatus,
  } = task || {};

  const hasDocumentRejected = documents.some(
    (doc) => doc.status === STATUS_REJECTED,
  );
  const initialCurrentStep = hasDocumentRejected
    ? STEP_TYPES.UPLOAD_ID
    : STEP_TYPES.ID_TYPE;
  const hasCoBorrower = pathOr(
    false,
    ['borrowersAndPropertyInformation', 'coborrowerIsPresent'],
    project,
  );

  // Hooks
  const [nextButtonDisableState, setNextButtonDisableState] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [wizardData, setWizardData] = useState(null);
  const [currentStep, setCurrentStep] = useState(initialCurrentStep);
  const [wizardIsLoading, setWizardIsLoading] = useState(false);
  const [showIntro, setShowIntro] = useState(hasCoBorrower);
  const [wizardIdTypes, setWizardIdTypes] = useState(WIZARD_ID_TYPES);
  const {addNotification} = useNotifications();
  const {markTaskAsOutstanding} = useMarkTaskAsOutstanding();
  const {submitGovIdDetails} = useSubmitGovInformation();

  //Constants
  const isOutstandingStatus = taskStatus === STATUS_OUTSTANDING;
  const isEditMode =
    !isOutstandingStatus || hasDocumentRejected || !isEmpty(govIdDetails);
  const wizardConfig = getWizardConfig(hasCoBorrower, currentStep);
  const {isCoBorrowerIntroPage, borrowersToShow} = wizardConfig;
  const isIntroPage = isCoBorrowerIntroPage && showIntro;

  const currentBorrower = useMemo(
    () =>
      currentStep > CO_BORROWER_STEP_TYPES.UPLOAD_ID
        ? BORROWERS_TYPES.CO_BORROWER
        : BORROWERS_TYPES.PRIMARY_BORROWER,
    [currentStep],
  );

  //Handlers
  const onCloseWizard = () => {
    setShowConfirmation(true);
  };

  const onAcceptConfirmation = async () => {
    setShowConfirmation(false);
    if (!isOutstandingStatus) {
      await markTaskAsOutstanding({variables: {taskId}});
    }
    onClose();
  };

  const onRejectConfirmation = () => {
    setShowConfirmation(false);
  };

  const onSubmit = async () => {
    setWizardIsLoading(true);

    const {selectedIdType, ...formData} = wizardData || {};
    const dataToSubmit = prepareDataToSubmit(
      taskId,
      selectedIdType?.value,
      formData,
      currentBorrower,
    );
    try {
      const {errors} = await submitGovIdDetails({variables: dataToSubmit});
      if (errors) {
        throw new Error(errors);
      }
    } catch (err) {
      addNotification(FAILED_NOTIFICATION);
    } finally {
      const eventName =
        currentStep === CO_BORROWER_STEP_TYPES.CO_ID_DETAILS
          ? GOV_ID_EVENTS.CO_BORROWER_FORM_SUBMIT
          : GOV_ID_EVENTS.BORROWER_FORM_SUBMIT;
      sendEvent(`Secure/${eventName}`, {
        idType: wizardData?.selectedIdType?.label,
      });
      setCurrentStep(currentStep + 1);

      setWizardIsLoading(false);
    }
  };

  const hideIntro = () => {
    setShowIntro(false);
  };
  if (!task) {
    return null;
  }

  return (
    <Wizard
      project={project}
      config={wizardConfig}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      onSubmit={onSubmit}
      wizardIsLoading={wizardIsLoading}
      wizardIdTypes={wizardIdTypes}
      nextButtonDisableState={nextButtonDisableState}
      isIntroPage={isIntroPage}
      onCloseWizard={onCloseWizard}
      onClose={onClose}
      documents={documents}
      govIdDetails={govIdDetails}
      taskId={taskId}
      hasCoBorrower={hasCoBorrower}
      {...props}>
      {borrowersToShow.map((borrower, key) => (
        <Borrower
          key={key}
          config={borrower}
          project={project}
          borrowerGovId={getBorrowerDetails(borrower?.type, govIdDetails)}
          isEditMode={isEditMode}
          setNextButtonDisableState={setNextButtonDisableState}
          currentStep={currentStep}
          setWizardIsLoading={setWizardIsLoading}
          setWizardIdType={setWizardIdTypes}
          submitWizardData={setWizardData}
          isIntroPage={isIntroPage}
          hideIntro={hideIntro}
          documents={documents}
          taskId={taskId}
          hasCoBorrower={hasCoBorrower}
          {...props}
        />
      ))}
      <ConfirmCloseTaskModal
        onCancelConfirm={onRejectConfirmation}
        onConfirm={onAcceptConfirmation}
        show={showConfirmation}
      />
    </Wizard>
  );
};

GovIdWizard.propTypes = {
  taskId: PropTypes.string,
  onClose: PropTypes.func,
};
export default GovIdWizard;
