import {useState} from 'react';

import {propEq} from 'ramda';

import {useUploadDocuments} from '@renofi/graphql';
import {sendEvent} from '@renofi/analytics';

import useRemoveBorrowerDocument from './useRemoveBorrowerDocument';

const useTaskHandlers = ({tasks = [], projectId, facet} = {}) => {
  const [loading, setLoading] = useState(false);
  const [removedDocument, setRemovedDocument] = useState({});
  const [uploadedDocument, setUploadedDocument] = useState({});

  const {setSubmitted, submitted} = useUploadDocuments({
    projectId,
    facet,
  });
  const {removeDocument} = useRemoveBorrowerDocument({
    projectId,
    facet,
  });

  const onRemoveDocument = async ({documentId, taskId}) => {
    const {title, documents: taskDocuments} = tasks.find(propEq('id', taskId));

    const {fileName} = taskDocuments.find(propEq('id', documentId));
    sendEvent('ButtonClick', {
      name: `${title} - ${fileName}`,
      text: 'Delete file',
    });
    setLoading(true);

    await removeDocument(documentId);

    const newSubmitted = submitted.filter(({id}) => id !== documentId);
    setSubmitted(newSubmitted);

    setLoading(false);
    setRemovedDocument({});
  };

  const onConfirmUpload = (documentObj) => {
    setUploadedDocument(documentObj);
  };

  const onShowPrompt = (documentObj) => {
    setRemovedDocument(documentObj);
  };

  return {
    onRemoveDocument,
    onConfirmUpload,
    onShowPrompt,
    loading,
    removedDocument,
    uploadedDocument,
  };
};

export default useTaskHandlers;
