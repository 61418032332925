import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {CONFIRM_SMS_NUMBER} from '../mutations';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(CONFIRM_SMS_NUMBER, {
      name: 'confirmSmsNumber',
    }),
    withHandlers({
      confirmSmsNumber:
        ({confirmSmsNumber}) =>
        async (variables) => {
          const response = await confirmSmsNumber({variables});
          const user = pathOr(
            null,
            ['data', 'confirmSmsNumber', 'user'],
            response,
          );
          return user;
        },
    }),
  );
