import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {getStateByCode} from '@renofi/utilities/src/states';
import {getFullName} from '@renofi/utilities/src/data';
import {getCountryByCode} from '@renofi/utilities/src/countries';
import {formatDate, formatDateTime} from '@renofi/utilities/src/dates';
import {Flex, Box} from '@renofi/components-internal';
import {successDark} from '@renofi/theme';
import {useToggledBorrowerProject} from '@renofi/graphql';

import {getIdType, sortBorrowers} from '../GovIdWizard/utils';

import BorrowerDocuments from './BorrowerDocuments';
import {
  Card,
  Container,
  Item,
  Label,
  Value,
  BorrowerLabel,
  DoubleCheckIcon,
} from './styled';

const GovIdTaskDetails = ({
  hasCoBorrower,
  govIdDetails,
  isApproved,
  documents,
  setCurrentDocumentId,
  onShowRejection,
  onRemoveFile,
  completedAt,
}) => {
  const [detailsToShow, setDetailsToShow] = useState([]);
  const reviewedDocumentText = `Reviewed ${formatDateTime(completedAt)}`;
  const {project} = useToggledBorrowerProject();

  useEffect(() => {
    const borrowersIdsDetails = govIdDetails.map((idDetails) => {
      const {
        idType,
        idNumber,
        issuingCountry,
        issuingState,
        issueDate,
        borrowerRole,
        expirationDate,
      } = idDetails || {};

      const detailsList = [
        {label: 'ID type', value: getIdType(idType)?.label},
        {label: 'ID number', value: idNumber},
        {label: 'Date of issue', value: issueDate ? formatDate(issueDate) : ''},
        {
          label: 'Date of expiration',
          value: expirationDate ? formatDate(expirationDate) : '',
        },
        {label: 'Issuing country', value: getCountryByCode(issuingCountry)},
      ];

      const isUsSelected = issuingCountry === 'US';
      if (isUsSelected) {
        detailsList.push({
          label: 'Issuing state',
          value: getStateByCode(issuingState),
        });
      }
      const borrowerInfo =
        borrowerRole === 'coborrower'
          ? {
              firstName:
                project?.borrowersAndPropertyInformation?.coborrowerFirstName,
              lastName:
                project?.borrowersAndPropertyInformation?.coborrowerLastName,
            }
          : project?.borrower;
      const id = borrowerRole === 'coborrower' ? 2 : 1;
      return {id, borrowerInfo, detailsList};
    });

    const sortedList = sortBorrowers(borrowersIdsDetails);
    setDetailsToShow([...sortedList]);
  }, [govIdDetails, project]);

  return detailsToShow?.map(({id, borrowerInfo, detailsList}, key) => {
    const isPrimaryBorrower = key === 0;

    return (
      <Box mb={4} key={id}>
        {hasCoBorrower && (
          <BorrowerLabel>
            <strong>{`${getFullName(borrowerInfo)}`}</strong>
            {` `}({isPrimaryBorrower ? 'Primary borrower' : 'Co-borrower'})
          </BorrowerLabel>
        )}

        <Card success={isApproved}>
          <Container>
            {detailsList?.map(({label, value}, i) => (
              <Item key={label} mr="4px">
                <Label mr="4px">{label}:</Label>
                <Value>{value || ''}</Value>
                {i < detailsList.length - 1 && ','}
              </Item>
            ))}
          </Container>
          {isApproved && (
            <Flex alignItems="center" color={successDark} mt={1}>
              <DoubleCheckIcon color={successDark} />
              <span className="reviewed-text">{reviewedDocumentText}</span>
            </Flex>
          )}
        </Card>
        <BorrowerDocuments
          taskDocuments={documents}
          isPrimaryBorrower={isPrimaryBorrower}
          setCurrentDocumentId={setCurrentDocumentId}
          onShowRejection={onShowRejection}
          onRemoveFile={onRemoveFile}
        />
      </Box>
    );
  });
};

GovIdTaskDetails.propTypes = {
  govIdDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      taskId: PropTypes.string,
      idType: PropTypes.string,
      idNumber: PropTypes.string,
      issuingCountry: PropTypes.string,
      issuingState: PropTypes.string,
      issueDate: PropTypes.string,
      expirationDate: PropTypes.string,
    }),
  ),
  isApproved: PropTypes.bool,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasCoBorrower: PropTypes.bool,
  setCurrentDocumentId: PropTypes.func,
  onShowRejection: PropTypes.func,
  onRemoveFile: PropTypes.func,
};
export default GovIdTaskDetails;
