import React, {memo, useState} from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';
import {Route, Switch, useHistory} from 'react-router-dom';
import {TaskItem} from 'modules/layout';
import {useCurrentTask} from 'modules/project';
import {getTaskButtonLabels} from 'modules/project/utils';
import {Wrapper} from 'modules/project/components/styled';

import {Button, DocumentViewer} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {isMobile} from '@renofi/theme';
import {RealEstateDetails} from '@renofi/modules-internal';

import RealEstateWizard from '../RealEstateWizard';
import {findPropertyDocuments} from '../RealEstateWizard/utils';

const RealEstateTask = ({task}) => {
  const history = useHistory();
  const [currentDocumentId, setCurrentDocumentId] = useState();

  const {isProcessing, sendEvent} = useCurrentTask({
    taskId: task.id,
    facet: task.facet,
  });

  const {project, projectId} = useToggledBorrowerProject();

  const {realEstateOwned, status, taskType} = task;

  const {subjectProperty, additionalProperties} = realEstateOwned || {};

  function getPropertyDocuments() {
    const subjectDocIds = subjectProperty?.documentIds || [];
    const additionalDocIds =
      additionalProperties?.map(({documentIds}) => documentIds) || [];
    const {documents: subjectDocuments} = findPropertyDocuments(
      subjectDocIds,
      task,
    );
    const {documents} = findPropertyDocuments(
      [...subjectDocIds, ...additionalDocIds.flat()],
      task,
    );

    return {documents, subjectDocuments};
  }

  const {documents} = getPropertyDocuments();

  const buttonLabel = getTaskButtonLabels({
    status,
    isContinueTask: !isNil(realEstateOwned),
    taskType,
  });

  function onOpenWizard() {
    sendEvent('Secure/Task-Started');

    history.push(
      `/${projectId}/tasks/borrower_information/real-estate-wizard/subject-property`,
    );
  }

  return (
    <>
      <TaskItem
        button={
          <Button
            block={isMobile()}
            data-testid="reo-wizard-btn"
            medium={isMobile()}
            secondary={isProcessing}
            onClick={onOpenWizard}>
            {buttonLabel}
          </Button>
        }
        task={task}>
        <Wrapper>
          <RealEstateDetails
            onClickDocument={setCurrentDocumentId}
            project={project}
            task={task}
          />

          {currentDocumentId && (
            <DocumentViewer
              hasControls
              selectable
              itemId={currentDocumentId}
              visible={!!currentDocumentId}
              documents={documents}
              onClose={() => setCurrentDocumentId(null)}
              showCloseButton
              showBackButton={false}
            />
          )}
        </Wrapper>
      </TaskItem>

      <Switch>
        <Route
          path={`/:projectId/tasks/:facet(borrower_information)/real-estate-wizard/:step?/:propertyId?/:type?`}
          render={() => <RealEstateWizard task={task} project={project} />}
        />
      </Switch>
    </>
  );
};

RealEstateTask.propTypes = {
  task: PropTypes.object.isRequired,
  hasCoBorrower: PropTypes.bool,
};

export default memo(RealEstateTask);
