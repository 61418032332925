import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {Text, Textarea, Box} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';

import {Wrapper} from './styled';

const ReasonForRenovating = ({info, update}) => {
  const {reasonForRenovating = ''} = info || {};

  useEffect(() => {
    sendEvent('Secure/Onboarding-Reason-For-Renovating');
  }, []);

  const onChange = (value) => {
    update({reasonForRenovating: value});
  };

  return (
    <Wrapper>
      <Text mb={4} fontSize={24}>
        Next, describe the reason why are you renovating?
      </Text>
      <Box width={[1, 0.5]}>
        <Textarea
          onChange={onChange}
          value={reasonForRenovating}
          rows={5}
          placeholder="Briefly describe your motivation for renovating"
          resizable
        />
      </Box>
    </Wrapper>
  );
};

ReasonForRenovating.propTypes = {
  info: PropTypes.shape({
    reasonForRenovating: PropTypes.string,
  }),
  update: PropTypes.func.isRequired,
};

export default ReasonForRenovating;
