import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';

import {CHANGE_SMS_NUMBER} from '../mutations';

import withMutation from './withMutation';

export default () =>
  compose(
    withMutation(CHANGE_SMS_NUMBER, {
      name: 'changeSmsNumber',
    }),
    withHandlers({
      changeSmsNumber:
        ({changeSmsNumber}) =>
        async (smsNumber) => {
          const response = await changeSmsNumber({variables: {smsNumber}});
          const user = pathOr(
            null,
            ['data', 'changeSmsNumber', 'user'],
            response,
          );
          return user;
        },
    }),
  );
