import {compose, withProps} from '@renofi/recompose';

import {withStorage} from './storage';
import isImpersonation from './isImpersonation';

export default compose(
  withStorage,
  withProps(({storage}) => ({
    impersonation: isImpersonation(storage),
  })),
);
