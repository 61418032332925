import {compose, mapProps} from '@renofi/recompose';
import {
  withSupportedContentTypes,
  withSupportedFileExtensions,
} from '@renofi/graphql';

export default compose(
  withSupportedContentTypes(),
  withSupportedFileExtensions(),
  mapProps(
    ({
      onlyImages,
      supportedContentTypes,
      supportedFileExtensions,
      ...props
    }) => {
      const accept = onlyImages
        ? supportedContentTypes.filter((type) => type.startsWith('image'))
        : supportedContentTypes.concat(supportedFileExtensions);

      return {
        ...props,
        accept,
      };
    },
  ),
);
