import {useEffect} from 'react';

import {
  Box,
  Checkbox,
  Flex,
  MoneyField,
  SelectField,
  Text,
} from '@renofi/components-internal';
import {useEnums} from '@renofi/graphql';
import {basic20} from '@renofi/theme';

const RightText = (
  <Text color={basic20} textAlign="right">
    / month
  </Text>
);

const MortgagePaymentsStep = ({
  isSubjectProperty = false,
  property = {},
  onChange,
}) => {
  const {enums} = useEnums();
  const occupancyTypes = enums?.realEstateOwnedPropertyOccupancyTypes || [];
  const propertyTypes = enums?.realEstateOwnedPropertyTypeTypes || [];
  const {
    existingMortgageLoan, // Boolean
    homeownerMonthlyInsurance, // Float
    homeownersAssociationFee, // Float
    mortgageBalance, // Float
    mortgageMonthlyInsurance, // Float
    mortgageMonthlyPayment, // Float
    otherMonthlyExpenses,
    propertyMonthlyTaxes, // Float
    propertyStructureType,
    propertyUseType,
  } = property;

  useEffect(() => {
    onChange(
      'homeownersAssociationFeesExist',
      Boolean(homeownersAssociationFee),
    );
  }, [homeownersAssociationFee]);

  const onChangeValue = (key) => (value) => {
    onChange(key, value);
  };

  return (
    <Box p={3}>
      <Text mb={3} fontSize={24}>
        Mortgage Details
      </Text>

      {isSubjectProperty ? (
        <Flex css={{gap: 16}} width={1}>
          <Box flex={0.5}>
            <SelectField
              label="Property type"
              onChange={onChangeValue('propertyStructureType')}
              options={propertyTypes}
              small
              value={propertyStructureType}
            />
          </Box>
          <Box flex={0.5}>
            <SelectField
              label="Current Occupancy"
              onChange={onChangeValue('propertyUseType')}
              options={occupancyTypes}
              small
              value={propertyUseType}
            />
          </Box>
        </Flex>
      ) : null}

      <Checkbox
        mb={24}
        checked={existingMortgageLoan}
        label="This property has mortgage(s)"
        onChange={(v) => onChange('existingMortgageLoan', v)}
      />

      <MoneyField
        disabled={!existingMortgageLoan}
        label="Total Mortgage Unpaid Balance"
        onChange={onChangeValue('mortgageBalance')}
        small
        value={mortgageBalance}
      />

      <MoneyField
        disabled={!existingMortgageLoan}
        label="Monthly mortgage payment"
        rightText={RightText}
        onChange={onChangeValue('mortgageMonthlyPayment')}
        small
        value={mortgageMonthlyPayment}
      />

      <Box mb={3}>
        <Text fontSize={16} as="em">
          if not included in the monthly mortgage payment:
        </Text>
      </Box>

      <Flex css={{gap: 16}} mb={3} width={1}>
        <MoneyField
          label="Property taxes"
          mb={0}
          onChange={onChangeValue('propertyMonthlyTaxes')}
          rightText={RightText}
          small
          value={propertyMonthlyTaxes}
        />

        <MoneyField
          label="Homeowner insurance"
          mb={0}
          onChange={onChangeValue('homeownerMonthlyInsurance')}
          rightText={RightText}
          small
          value={homeownerMonthlyInsurance}
        />
      </Flex>

      <Flex css={{gap: 16}} mb={3} width={1}>
        <MoneyField
          disabled={!existingMortgageLoan}
          label="Mortgage insurance"
          mb={0}
          onChange={onChangeValue('mortgageMonthlyInsurance')}
          rightText={RightText}
          small
          value={mortgageMonthlyInsurance}
        />

        <MoneyField
          label="HOA"
          mb={0}
          onChange={onChangeValue('homeownersAssociationFee')}
          rightText={RightText}
          small
          value={homeownersAssociationFee}
        />
      </Flex>

      <Box mb={3} width={['100%', 'calc(50% - 8px)']}>
        <MoneyField
          label="Other"
          mb={0}
          onChange={onChangeValue('otherMonthlyExpenses')}
          rightText={RightText}
          small
          value={otherMonthlyExpenses}
        />
      </Box>
    </Box>
  );
};

export default MortgagePaymentsStep;
