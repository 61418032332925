import {isNil} from 'ramda';

import {realEstateOwnedPropertyTypes} from '@renofi/utilities/src/enumTypes';

import {findPropertyDocuments} from '../utils';

export default function useStepsLabels(documentIds, task) {
  const {statementFiles, policyFiles, taxFiles, feesFiles} =
    findPropertyDocuments(documentIds, task);

  return (parentRoute, taxAndInsuranceIncluded, property, propertyId) => {
    const {streetAddressOne, propertyStructureType} = property || {};

    function filesCount(files) {
      return files?.length > 0
        ? `${files?.length} file${files?.length > 1 ? 's' : ''}`
        : '';
    }

    function root() {
      return `${parentRoute}${propertyId ? '/' + propertyId : ''}`;
    }

    return [
      {id: root(), value: streetAddressOne},
      {id: `${root()}/root`, value: streetAddressOne},
      {id: `${root()}/address`, value: streetAddressOne},
      {
        id: `${root()}/details`,
        value: realEstateOwnedPropertyTypes[propertyStructureType],
      },
      {
        id: `${root()}/mortgage-details`,
        value: isNil(taxAndInsuranceIncluded)
          ? ''
          : `Taxes ${taxAndInsuranceIncluded ? 'included' : 'excluded'}`,
      },
      {id: `${root()}/mortgage-statement`, value: filesCount(statementFiles)},
      {id: `${root()}/tax`, value: filesCount(taxFiles)},
      {id: `${root()}/insurance`, value: filesCount(policyFiles)},
      {id: `${root()}/fees`, value: filesCount(feesFiles)},
    ];
  };
}
