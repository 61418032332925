import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {ListBox, ListItem, IconBox, Label} from 'lib/styles/ListBox.styles';

import {Text} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';
import Accept from '@renofi/icons/src/Accept';
import {propertyUseTypes} from '@renofi/utilities/src/enumTypes';

import PrimaryResidence from './icons/propertyUsage/PrimaryResidence';
import SecondHome from './icons/propertyUsage/SecondHome';
import InvestmentProperty from './icons/propertyUsage/InvestmentProperty';
import {Wrapper} from './styled';

const ICON_MAPPER = {
  primary_residence: <PrimaryResidence />,
  secondary_residence: <SecondHome />,
  investment_property: <InvestmentProperty />,
};

const propertyUseTypeOptions = Object.keys(propertyUseTypes).map((key) => {
  return {
    value: key,
    label: propertyUseTypes[key],
    icon: ICON_MAPPER[key] || '',
  };
});

const PropertyUsage = ({info, update}) => {
  const onChange = (value) => {
    sendEvent('Secure/Onboarding-User-PropertyUsage-Selected');
    update({propertyUseType: value});
  };

  useEffect(() => {
    sendEvent('Secure/Onboarding-User-PropertyUsage-Presented');
  }, []);

  return (
    <Wrapper>
      <Text mb={4} fontSize={24}>
        How do you plan to use the property?
      </Text>

      <ListBox>
        {propertyUseTypeOptions.map(({icon, label, value}) => (
          <ListItem
            key={value}
            active={info?.propertyUseType === value}
            onClick={() => onChange(value)}>
            <IconBox>
              {info?.propertyUseType === value ? (
                <Accept color="#00A0FF" />
              ) : (
                icon
              )}
            </IconBox>
            <Label>{label}</Label>
          </ListItem>
        ))}
      </ListBox>
    </Wrapper>
  );
};

PropertyUsage.propTypes = {
  info: PropTypes.shape({
    propertyUseType: PropTypes.string,
  }),
  update: PropTypes.func.isRequired,
};

export default PropertyUsage;
