import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {v4} from 'uuid';

import {Box, Button, Modal, Text} from '@renofi/components-internal';
import {
  useRemoveContractorInformation,
  useSubmitContractorInformation,
} from '@renofi/graphql';
import {basic55} from '@renofi/theme';
import {sendEvent} from '@renofi/analytics';
import {validateUuidProp} from '@renofi/utilities/src/react';

import {getContractorProps} from '../../utils';

import useConfirmEditModal from './useConfirmEditModal';

const ConfirmEditModal = ({
  contractor,
  onConfirmEdit,
  onFinishReplace,
  taskId,
}) => {
  const {show, setShowConfirmEditModal} = useConfirmEditModal();
  const {loading: isRemoving, removeContractorInformation} =
    useRemoveContractorInformation();
  const {loading: isSubmitting, submitContractorInformation} =
    useSubmitContractorInformation();
  const loading = isRemoving || isSubmitting;

  const onClickYes = async () => {
    const contractorInformation = getContractorProps(contractor);

    await removeContractorInformation({variables: {id: contractor.id}});
    const id = v4();
    const rsp = await submitContractorInformation({
      variables: {
        id,
        contractorInformation,
        taskId,
      },
    });

    if (!rsp.errors) {
      setShowConfirmEditModal(false);
      onFinishReplace();
    }
  };

  const onClickNo = async () => {
    setShowConfirmEditModal(false);
    onConfirmEdit();
  };

  useEffect(() => {
    if (show) {
      sendEvent('Secure/Contractors/New-GC-Detected', {contractor});
    }
  }, [show]);

  return (
    <Modal show={show}>
      <Text mb={5} mt={3} color={basic55} fontSize={18}>
        We noticed you changed a lot of details about this contractor: is this a
        new contractor to replace the existing one?
      </Text>
      <Button
        block
        disabled={loading}
        loading={loading}
        medium
        onClick={onClickYes}>
        Yes, this is a new contractor
      </Button>
      <Box width={1} mt={3}>
        <Button
          block
          disabled={loading}
          medium
          onClick={onClickNo}
          variant="secondary">
          No, just update the details please
        </Button>
      </Box>
    </Modal>
  );
};

ConfirmEditModal.propTypes = {
  contractor: PropTypes.shape({
    id: PropTypes.string,
  }),
  onConfirmEdit: PropTypes.func.isRequired,
  onFinishReplace: PropTypes.func.isRequired,
  taskId: validateUuidProp,
};

export default ConfirmEditModal;
