import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/analytics';

import {STEP_MAPPER} from '../constants';

import BaseContent from './BaseContent';

const CoBorrowerProperty = ({setStep, update}) => {
  const nextStep = async (isAccepted) => {
    await update({coborrowerIsOnTitle: isAccepted});
    setStep(STEP_MAPPER.BorrowerInfo);
    sendEvent('Secure/Onboarding-Coborrower-Title-Selected');
  };

  useEffect(() => {
    sendEvent('Secure/Onboarding-Coborrower-Title-Presented');
  }, []);

  return (
    <BaseContent
      mainContent={'Are they on the property title?'}
      onAccept={() => nextStep(true)}
      onDecline={() => nextStep(false)}
    />
  );
};

CoBorrowerProperty.propTypes = {
  setStep: PropTypes.func,
  update: PropTypes.func,
};

CoBorrowerProperty.defaultProps = {
  loading: false,
};

export default CoBorrowerProperty;
