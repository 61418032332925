import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {Button} from '@renofi/components-internal';
import {
  useMarkTaskFinishedByBorrower,
  useStartVerification,
  useToggledBorrowerProject,
} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities';
import Sentry from '@renofi/utilities/src/sentry';

import {WizardUploadTask} from '../../../Task';
import {TASK_STATUSES} from '../../../Project/constants';
import {useAddEditButtonVariant, useCurrentTask} from '../../../../hooks';

import IdentityExtraDetails from './IdentityExtraDetails';
import IdentityVerificationHelp from './IdentityVerificationHelp';
import {isVerificationDone} from './utils';

const IdentityVerificationWizard = ({onTaskComplete, task}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {project} = useToggledBorrowerProject();
  const [, variant] = useAddEditButtonVariant({
    taskId: task.id,
    facet: task.facet,
  });
  const {addNotification} = useNotifications();

  const {startVerification} = useStartVerification();
  const {markTaskFinishedByBorrower} = useMarkTaskFinishedByBorrower();
  const {sendEvent} = useCurrentTask({taskId: task?.id});

  const doneVerifications = {
    borrower: isVerificationDone(task, 'borrower'),
    coborrower: isVerificationDone(task, 'coborrower'),
  };
  const isBorrowerVerificationPending = !doneVerifications['borrower'];
  const isVerificationDoneByAny =
    doneVerifications['borrower'] || doneVerifications['coborrower'];

  function loadStripeLibrary(callback) {
    let script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.onload = function () {
      callback();
    };
    document.head.appendChild(script); //or something of the likes
  }

  function initStripe() {
    loadStripeLibrary(openStripeModal);
  }

  async function openStripeModal() {
    setIsLoading(true);
    const rsp = await startVerification({
      variables: {
        email: project?.borrower?.email,
        projectId: project?.id,
        taskId: task?.id,
        borrowerRole: 'borrower',
      },
    });
    const errors = rsp?.errors;
    const clientSecret = rsp?.data?.startVerification?.clientSecret;

    if (!clientSecret) {
      onVerificationError(errors);
      return;
    }

    const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const result = await stripe?.verifyIdentity(clientSecret);

    if (!result.error) {
      onTaskComplete(task?.id, TASK_STATUSES.completed);

      await markTaskFinishedByBorrower({variables: {taskId: task.id}});
      sendEvent('Secure/Task-Finished');
    }
    setTimeout(() => setIsLoading(false), 1500);
  }

  function onVerificationError(errors) {
    addNotification({
      variant: 'danger',
      content: 'Something went wrong. Please try again later.',
      type: 'snackbar',
    });

    Sentry.captureException('Secure app identity verification failed.', {
      extra: errors,
    });
    sendEvent('Secure/Identity-Verification-Failed', {
      taskId: task.id,
      taskType: task.taskType,
    });

    setIsLoading(false);
  }

  const button = () =>
    isBorrowerVerificationPending ? (
      <Button
        css={{width: 84}}
        disabled={isLoading}
        loading={isLoading}
        onClick={initStripe}
        variant={variant}>
        Add ID
      </Button>
    ) : (
      <></>
    );

  return (
    <>
      <WizardUploadTask
        extra={IdentityExtraDetails}
        extraDetailsProps={{}}
        showDetails={isVerificationDoneByAny}
        task={task}
        onTaskComplete={onTaskComplete}
        wizardProps={{width: 900}}
        customAddEditButton={button}>
        <IdentityVerificationHelp mt={isBorrowerVerificationPending ? 0 : 24} />
      </WizardUploadTask>
    </>
  );
};

IdentityVerificationWizard.propTypes = {
  onTaskComplete: PropTypes.func,
  task: PropTypes.object.isRequired,
};

export default IdentityVerificationWizard;
