import {useContext, useEffect} from 'react';

import {sendEvent} from '@renofi/analytics';

import {addMonths} from '../dates';
import {Context} from '../storage';

const useReturnsAfterOneMonthAnalytics = (user) => {
  const storage = useContext(Context);

  useEffect(() => {
    const afterOneMonth = storage.getItem('after:one:month');

    if (!afterOneMonth) {
      storage.setItem('after:one:month', addMonths(1));
      return;
    }

    const hasPastOneMonth = new Date() > new Date(afterOneMonth);
    if (hasPastOneMonth) {
      sendEvent('Secure/Returns-After-One-Month', {
        email: user?.email,
        id: user?.id,
      });
    }
  }, []);
};

export default useReturnsAfterOneMonthAnalytics;
