import {isNil, pick} from 'lodash';

import {SUBJECT_PROPERTY_INPUT_PROPS} from './constants';

export function initialFormData(task) {
  const {realEstateOwned} = task;

  if (isNil(realEstateOwned)) return {};

  const {
    subjectProperty = {},
    additionalProperties = [],
    soldProperties = [],
  } = realEstateOwned;

  return {
    ...realEstateOwned,
    subjectProperty: pick(subjectProperty, SUBJECT_PROPERTY_INPUT_PROPS),
    additionalProperties: additionalProperties?.map((property) => ({
      id: property.id,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      county: property.county,
      streetAddressTwo: property.streetAddressTwo,
      streetAddressOne: property.streetAddressOne,
      propertyStructureType: property.propertyStructureType,
      propertyUseType: property.propertyUseType,
      currentOccupancyOther: property.currentOccupancyOther,
      homeownerMonthlyInsurance: property.homeownerMonthlyInsurance,
      intendedOccupancy: property.intendedOccupancy,
      mortgageBalance: property.mortgageBalance,
      mortgageMonthlyInsurance: property.mortgageMonthlyInsurance,
      mortgageMonthlyPayment: property.mortgageMonthlyPayment,
      otherMonthlyExpenses: property.otherMonthlyExpenses,
      propertyMonthlyTaxes: property.propertyMonthlyTaxes,
      propertyStatus: property.propertyStatus,
      yearPurchased: property.yearPurchased,
      marketValue: property.marketValue,
      rentalIncome: property.rentalIncome,
      existingMortgageLoan: property.existingMortgageLoan,
      taxAndInsuranceIncluded: property.taxAndInsuranceIncluded,
      homeownersAssociationFee: property.homeownersAssociationFee,
      homeownersAssociationFeesIncluded:
        property.homeownersAssociationFeesIncluded,
      homeownersAssociationFeesExist: property.homeownersAssociationFeesExist,
      coborrowerOwned: property.coborrowerOwned,
      primaryBorrowerOwned: property.primaryBorrowerOwned,
    })),
    soldProperties: soldProperties?.map((property) => ({
      id: property.id,
      city: property.city,
      state: property.state,
      zipCode: property.zipCode,
      county: property.county,
      streetAddressTwo: property.streetAddressTwo,
      streetAddressOne: property.streetAddressOne,
      propertyStructureType: property.propertyStructureType,
      primaryBorrowerOwned: property.primaryBorrowerOwned,
      coborrowerOwned: property.coborrowerOwned,
      soldDate: property?.soldDate,
    })),
  };
}

export function findPropertyDocuments(documentIds, task) {
  const statementFiles = findDocuments('mortgage_statement');
  const taxFiles = findDocuments('property_tax');
  const policyFiles = findDocuments('homeowners_insurance_policy');
  const feesFiles = findDocuments('homeowners_association_fees');
  const documents = [
    ...statementFiles,
    ...taxFiles,
    ...policyFiles,
    ...feesFiles,
  ];

  function findDocuments(documentType) {
    return (
      task.documents?.filter(
        (doc) =>
          documentIds?.includes(doc.id) && doc.documentType === documentType,
      ) || []
    );
  }

  return {statementFiles, taxFiles, policyFiles, feesFiles, documents};
}
