import {compose} from '@renofi/recompose';

import {GET_SUPPORTED_CONTENT_TYPES} from '../queries';

import withQuery from './withQuery';

export default ({options = {}} = {}) =>
  compose(
    withQuery({
      query: GET_SUPPORTED_CONTENT_TYPES,
      props: ({data, ...props}) => ({
        supportedContentTypes:
          data?.supportedContentTypes ||
          data?.previousData?.supportedContentTypes ||
          [],
        ...props,
      }),
      options,
    }),
  );
