import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';
import {differenceInMonths, startOfDay} from 'date-fns';
import {DateField} from 'modules/layout';

import {useNotifications} from '@renofi/utilities/src/hooks';
import {useCreateRenovationUpdate} from '@renofi/graphql';
import {
  GET_PROJECT_RENOVATION_UPDATES,
  GET_PROJECT_TIMELINE,
} from '@renofi/graphql';
import {RENOVATION_UPDATE_KINDS} from '@renofi/modules-internal';
import {
  Alert,
  Button,
  Box,
  Flex,
  Modal,
  RadioGroup,
} from '@renofi/components-internal';

const RADIO_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
const NOW = startOfDay(new Date());

const RenovationStartDateModal = ({onClose, projectId}) => {
  const {addNotification} = useNotifications();

  const [isRenovationStarted, setIsRenovationStarted] = useState(null);
  const [date, setDate] = useState(null);
  const [dateTooLongAgo, setDateTooLongAgo] = useState(false);

  const {createRenovationUpdate, loading} = useCreateRenovationUpdate({
    refetchQueries: [GET_PROJECT_RENOVATION_UPDATES, GET_PROJECT_TIMELINE],
  });

  const dateLabel = isRenovationStarted
    ? 'When your renovation started?'
    : 'When is your renovation scheduled to start?';

  const onAdd = async () => {
    const kind = isRenovationStarted
      ? RENOVATION_UPDATE_KINDS.renovation_started
      : RENOVATION_UPDATE_KINDS.expected_renovation_start;

    const params = {
      projectId,
      date,
      kind,
    };

    try {
      await createRenovationUpdate({
        variables: params,
      });

      addNotification({
        variant: 'success',
        content: 'Renovation start date is updated.',
      });

      onClose();
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to update renovation start date.',
      });
    }
  };

  const onChangeDate = (d) => {
    const diff = d ? differenceInMonths(new Date(d), NOW) : 0;
    const tooLongAgo = diff < -6;

    setDateTooLongAgo(tooLongAgo);
    setDate(tooLongAgo ? null : d);
  };

  return (
    <Modal
      show
      disabled={!date}
      height={720}
      onClose={onClose}
      header="When is your renovation scheduled to start?"
      acceptLabel="Add"
      footer={
        <Flex width={1} css={{gap: 12}} justifyContent="flex-end">
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!date || loading} onClick={onAdd}>
            Add
          </Button>
        </Flex>
      }>
      <Flex flexDirection="column" css={{gap: 10}}>
        <Alert variant="info">
          We’ll email you on the date you provide below to ask if the renovation
          has started.
        </Alert>

        <RadioGroup
          mb={12}
          label="Has the renovation started?"
          options={RADIO_OPTIONS}
          value={isRenovationStarted}
          onChange={setIsRenovationStarted}
        />

        {!isNil(isRenovationStarted) ? (
          <Box width={3 / 5}>
            <DateField
              label={dateLabel}
              value={date}
              onChange={onChangeDate}
              onError={() => setDate(null)}
              error={
                dateTooLongAgo
                  ? 'Dates should not be older than 6 months'
                  : null
              }
            />
          </Box>
        ) : null}
      </Flex>
    </Modal>
  );
};

RenovationStartDateModal.propTypes = {
  onClose: PropTypes.func,
  projectId: PropTypes.string,
};

export default RenovationStartDateModal;
