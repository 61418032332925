import React, {useEffect, useState} from 'react';

import {useDropzone} from 'react-dropzone';
import bytes from 'bytes';
import PropTypes from 'prop-types';

import {Link} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';
import {
  useSupportedContentTypes,
  useSupportedFileExtensions,
} from '@renofi/graphql';
import getMimeTypes from '@renofi/utilities/src/mimeTypes';

import arrowIcon from './Arrow.svg';
import cloudIcon from './Cloud.svg';
import {DropZoneWrapper, Arrow, IconWrapper, TextPrompt} from './styled';

const DEFAULT_FILE_SIZE = '256MB';

const FileDropZone = ({
  disabled,
  hasDocuments,
  minimize = false,
  multiple = true,
  onAcceptFiles,
  onRejectFiles,
  onlyImages = false,
  promptNoun,
}) => {
  const {supportedContentTypes} = useSupportedContentTypes();
  const {supportedFileExtensions} = useSupportedFileExtensions();

  const [hover, setHover] = useState(false);
  const [accept, setAccept] = useState(getMimeTypes());
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const onAnimationEnd = async () => {
    await onAcceptFiles(acceptedFiles);
    setAcceptedFiles([]);
  };

  const onFilesDrop = async (droppedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      onRejectFiles(rejectedFiles);
    }

    if (!hasDocuments) {
      return setAcceptedFiles(droppedFiles);
    }

    await onAcceptFiles(droppedFiles);
    setAcceptedFiles([]);
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: accept.join(', '),
    onDrop: onFilesDrop,
    disabled,
    maxSize: bytes(DEFAULT_FILE_SIZE),
    multiple,
  });

  useEffect(() => {
    const accepted = onlyImages
      ? supportedContentTypes.filter((type) => type.startsWith('image'))
      : supportedContentTypes.concat(supportedFileExtensions);

    setAccept(accepted);
  }, [supportedContentTypes.length, supportedFileExtensions.length]);

  const dropped = Boolean(acceptedFiles.length);

  return (
    <DropZoneWrapper
      disabled={disabled}
      minimize={minimize || hasDocuments}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...getRootProps()}>
      <input {...getInputProps()} />
      <IconWrapper
        dropped={dropped}
        minimize={minimize || hasDocuments}
        onAnimationEnd={onAnimationEnd}>
        <img src={cloudIcon} />
        <Arrow
          minimize={minimize || hasDocuments}
          hover={hover || isDragActive}
          src={arrowIcon}
        />
      </IconWrapper>

      <TextPrompt mx={2} minimize={minimize || hasDocuments} color={basic55}>
        Drop {promptNoun} here to upload or <Link>browse files</Link>
      </TextPrompt>
    </DropZoneWrapper>
  );
};

FileDropZone.propTypes = {
  disabled: PropTypes.bool,
  hasDocuments: PropTypes.bool,
  minimize: PropTypes.bool,
  multiple: PropTypes.bool,
  onAcceptFiles: PropTypes.func,
  onRejectFiles: PropTypes.func,
  onlyImages: PropTypes.bool,
  promptNoun: PropTypes.string,
};

export default FileDropZone;
