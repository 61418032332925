import React, {useEffect} from 'react';

import {isNil} from 'lodash';
import PropTypes from 'prop-types';
import {Redirect, Route, useParams, useRouteMatch} from 'react-router-dom';

import {Text} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';

import {findPropertyDocuments} from '../utils';
import PropertyAddressStep from '../PropertyAddressStep';
import AdditionalPropertyDetailsStep from '../AdditionalPropertyDetailsStep';
import PropertyCount from '../PropertyCount';
import MortgageDetailsStep from '../MortgageDetailsStep';
import MortgageStatementStep from '../MortgageStatementStep';
import {additionalPropertyStep} from '../steps';
import PropertyTaxStep from '../PropertyTaxStep';
import InsurancePolicyStep from '../InsurancePolicyStep';
import useStepsLabels from '../hooks/useStepsLabels';
import FeesStep from '../FeesStep';
import useTogglePropertySteps from '../hooks/useTogglePropertySteps';
import MortgagePaymentsStep from '../MortgagePaymentsStep';

const AdditionalProperties = ({
  isPaymentsEnabled = false,
  names,
  formData,
  onChange,
  task,
  removeStep,
  addStep,
  updateStep,
  toggleStep,
  status,
  borrower,
  coborrower,
  borrowersInfo,
}) => {
  const {propertyId, type, step} = useParams();
  const match = useRouteMatch();
  const {project} = useToggledBorrowerProject();
  const {borrowersAndPropertyInformation} = project || {};

  const {additionalProperties = [], additionalPropertyNumber} = formData;
  const index = propertyId - 2; // property #2 is a first item in additionalProperties array
  const {documentIds} = task.realEstateOwned?.additionalProperties[index] || {};
  const property = additionalProperties?.length
    ? additionalProperties[index]
    : {};
  const {taxAndInsuranceIncluded} = property || {};
  const getStepLabels = useStepsLabels(documentIds, task);
  const togglePropertySteps = useTogglePropertySteps({isPaymentsEnabled});
  const {statementFiles, policyFiles, taxFiles, feesFiles} =
    findPropertyDocuments(documentIds, task);

  useEffect(() => {
    updateWizardSteps();
  }, [
    propertyId,
    type,
    step,
    additionalPropertyNumber,
    JSON.stringify(additionalProperties),
  ]);

  useEffect(() => {
    if (status === 'updated') updateWizardSteps();
  }, [status]);

  function onChangePropertyNumber(newValue) {
    onChange('additionalPropertyNumber', newValue);
  }

  function onChangeProperty(newValue) {
    onChange('additionalProperties', updateProperties(newValue));
  }

  function updateProperties(newValue) {
    if (additionalProperties?.hasOwnProperty(index)) {
      return additionalProperties.map((value, i) =>
        index === i ? {...value, ...newValue} : value,
      );
    }
    return [...additionalProperties, newValue];
  }

  function getTotalValue() {
    if (isNil(additionalPropertyNumber)) return '';
    return String(additionalPropertyNumber || 'No');
  }

  function updateWizardSteps() {
    for (let i = 1; i <= 20; i++) {
      if (i <= additionalPropertyNumber) {
        const prevId = i === 1 ? 'additional' : `additional/${i}/root`;
        addStep(additionalPropertyStep(i + 1), prevId);

        if (isNil(additionalProperties) || additionalProperties?.length < 1)
          continue;

        const property = additionalProperties[i - 1];
        const labels = getStepLabels(
          'additional',
          taxAndInsuranceIncluded,
          property,
          i + 1,
        );
        togglePropertySteps(toggleStep, `additional/${i + 1}`, property);
        labels.forEach(({id, value}) => {
          updateStep({id, key: 'info', value});
        });
      } else {
        removeStep(`additional/${i + 1}/root`);
      }
    }

    updateStep({
      id: 'additional',
      key: 'info',
      value: getTotalValue(),
    });
  }

  return (
    <>
      <Route
        exact
        path={`${match.path}/:step(additional)`}
        render={() => (
          <PropertyCount
            title={`Does ${names} own additional properties other than the subject property?`}
            subtitle={`How many properties do ${names} own?`}
            onChange={onChangePropertyNumber}
            value={additionalPropertyNumber}
          />
        )}
      />
      <Route path={`${match.path}/:step(additional)/:propertyId/:type(root)`}>
        <Redirect to={`${match.url.replace('root', 'address')}`} />
      </Route>
      <Route
        path={`${match.path}/:step(additional)/:propertyId/:type(address)`}
        render={() => (
          <PropertyAddressStep
            onChange={onChangeProperty}
            property={property}
            title={
              <Text>
                Next, enter the <strong>address</strong> for property #
                {propertyId}.
              </Text>
            }
          />
        )}
      />
      <Route
        path={`${match.path}/:step(additional)/:propertyId/:type(details)`}
        render={() => (
          <AdditionalPropertyDetailsStep
            onChange={onChangeProperty}
            property={property}
            borrower={borrower}
            coborrower={coborrower}
            borrowersInfo={borrowersInfo}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(additional)/:propertyId/:type(mortgage-details)`}
        render={() =>
          isPaymentsEnabled ? (
            <MortgagePaymentsStep
              borrowersAndPropertyInformation={borrowersAndPropertyInformation}
              onChange={(key, value) =>
                onChangeProperty({...property, [key]: value})
              }
              property={property}
            />
          ) : (
            <MortgageDetailsStep
              address={property?.streetAddressOne}
              existingMortgageLoan={property?.existingMortgageLoan}
              taxAndInsuranceIncluded={property?.taxAndInsuranceIncluded}
              homeownersAssociationFeesExist={
                property?.homeownersAssociationFeesExist
              }
              homeownersAssociationFeesIncluded={
                property?.homeownersAssociationFeesIncluded
              }
              onChange={(key, value) =>
                onChangeProperty({...property, [key]: value})
              }
            />
          )
        }
      />
      <Route
        path={`${match.path}/:step(additional)/:propertyId/:type(mortgage-statement)`}
        render={() => (
          <MortgageStatementStep
            documents={statementFiles}
            address={property?.streetAddressOne}
            task={task}
            onUploadComplete={updateWizardSteps}
            realEstateOwnedPropertyId={property?.id}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(additional)/:propertyId/:type(tax)`}
        render={() => (
          <PropertyTaxStep
            documents={taxFiles}
            task={task}
            address={property?.streetAddressOne}
            onUploadComplete={updateWizardSteps}
            realEstateOwnedPropertyId={property?.id}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(additional)/:propertyId/:type(insurance)`}
        render={() => (
          <InsurancePolicyStep
            documents={policyFiles}
            task={task}
            address={property?.streetAddressOne}
            onUploadComplete={updateWizardSteps}
            realEstateOwnedPropertyId={property.id}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(additional)/:propertyId/:type(fees)`}
        render={() => (
          <FeesStep
            documents={feesFiles}
            task={task}
            address={property?.streetAddressOne}
            onUploadComplete={updateWizardSteps}
            realEstateOwnedPropertyId={property.id}
          />
        )}
      />
    </>
  );
};

AdditionalProperties.propTypes = {
  isPaymentsEnabled: PropTypes.bool,
  names: PropTypes.string,
  formData: PropTypes.object,
  task: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  addStep: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
  toggleStep: PropTypes.func.isRequired,
  status: PropTypes.string,
  borrower: PropTypes.object,
  coborrower: PropTypes.object,
  borrowersInfo: PropTypes.object,
};

export default AdditionalProperties;
