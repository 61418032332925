import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {Text, Textarea, Box} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';

import {Wrapper} from './styled';

const TypeOfRenovation = ({info, update}) => {
  const {renovationTypeDescription = ''} = info || {};

  useEffect(() => {
    sendEvent('Secure/Onboarding-Type-Of-Renovation');
  }, []);

  const onChange = (value) => {
    update({renovationTypeDescription: value});
  };

  return (
    <Wrapper>
      <Text mb={4} fontSize={24}>
        Briefly describe the type of renovation you are planning
      </Text>
      <Box width={[1, 0.5]}>
        <Textarea
          onChange={onChange}
          value={renovationTypeDescription}
          rows={5}
          resizable
        />
      </Box>
    </Wrapper>
  );
};

TypeOfRenovation.propTypes = {
  info: PropTypes.shape({
    renovationTypeDescription: PropTypes.string,
  }),
  update: PropTypes.func.isRequired,
};

export default TypeOfRenovation;
