import {pick} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';
import {TextField} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';
import omitProps from '@renofi/utilities/src/omitProps';

export default compose(
  withHandlers({
    track:
      ({onFocus, label, placeholder, ...props}) =>
      (type, event) => {
        sendEvent(type, {
          text: label || placeholder,
          ...pick(['name'], props),
        });
        if (onFocus) {
          onFocus(event);
        }
      },
  }),
  withHandlers({
    onBlur:
      ({track}) =>
      (event) => {
        track('FieldBlur', event);
      },
    onFocus:
      ({track}) =>
      (event) => {
        track('FieldFocus', event);
      },
  }),
  omitProps(['track']),
)(TextField);
