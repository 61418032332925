import React from 'react';

import {LENDER_RATES_URL} from 'config';
import {Help} from 'modules/layout';
import {Review} from 'modules/review';

import {
  AlertWithIcon,
  Box,
  Container,
  Flex,
  LenderLogo,
  Link,
  Text,
  PageTitle,
} from '@renofi/components-internal';
import {Card} from '@renofi/components-internal/src/next';
import {openTab} from '@renofi/utilities/src/window';
import formatNumber from '@renofi/utilities/src/formatNumber';
import formatMoney from '@renofi/utilities/src/formatMoney';
import getLenderShortName from '@renofi/utilities/src/getLenderShortName';
import {basic80} from '@renofi/theme/src/colors';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {isTpoApplication} from '@renofi/utilities/src/product-confirmation';

import {PRODUCT_DETAILS_TASK_TYPE} from '../../../project/components/ProductConfirmation/constants';

import HeaderContent from './HeaderContent';
import Contacts from './Contacts';
import {
  StyledTitle,
  Content,
  Label,
  Value,
  Wrapper,
  StyledFlex,
  StyledBox,
} from './styled';
import SummaryOfInformation from './components/SummaryOfInformation/Component';
import NonTpoInformation from './components/NonTpoInformation/Component';
import ContactInformation from './components/ContactInformation/Component';
import {
  getCreatedDate,
  getIsDisabled,
  getLender,
  getStatusHeader,
  isComplete,
} from './utils';

const Lender = () => {
  const {project} = useToggledBorrowerProject();
  const lender = getLender(project);
  const createdDate = getCreatedDate(project);

  const lenderName = lender?.name || 'Lender';

  const {
    fastTracked,
    renovationReadyFile,
    tasks,
    borrower,
    loanValue,
    loanType,
    positionedInterestRate,
  } = project || {};

  const disabled = getIsDisabled(project, renovationReadyFile);
  const complete = isComplete(renovationReadyFile);
  const isTpo = isTpoApplication(project);

  const productDetails = tasks?.find(
    (task) => task.taskType === PRODUCT_DETAILS_TASK_TYPE,
  );
  const hasSummaryDetails = !complete && lender && productDetails;
  const {productDetailsConfirmation} = productDetails || {};
  const {appraisedValue} = productDetailsConfirmation || {};
  const borrowerName = borrower?.firstName;
  const isNormalView = disabled || complete;
  const lenderShortName = getLenderShortName(lenderName);
  const contactText = lender?.contactInformation2
    ? 'contacts are'
    : 'contact is';

  const headerText = getStatusHeader({
    complete,
    disabled,
    isTpo,
    lenderName,
    borrowerName,
    fastTracked,
  });

  return (
    <Container narrow mt={16}>
      {isNormalView ? (
        <StyledTitle>
          <PageTitle backButton={false} text="Apply with Lender" />
        </StyledTitle>
      ) : null}
      <Content
        complete={complete}
        hideBackgroundImage={!isNormalView}
        head={headerText}>
        <HeaderContent
          complete={complete}
          disabled={disabled}
          isTpo={isTpo}
          fastTracked={fastTracked}
          lenderName={lenderName}
        />
      </Content>
      {complete ? <Review wide /> : null}
      {!complete ? (
        <Card py={24}>
          <Wrapper disabled={disabled}>
            <StyledBox mb={40}>
              <StyledFlex role="your-lender">
                <Box>
                  <Label>Your lender</Label>
                  {!disabled && (
                    <LenderLogo
                      css={{
                        marginTop: 4,
                        width: 152,
                        height: 'auto',
                      }}
                      lender={lender}
                    />
                  )}
                </Box>
                <Box ml={48}>
                  <Label>Loan amount</Label>
                  {!disabled ? (
                    <Flex>
                      <Value>{formatMoney(loanValue)}</Value>
                    </Flex>
                  ) : null}
                </Box>
                <Box ml={48}>
                  <Label>Loan Type</Label>
                  {!disabled ? (
                    <Flex css={{whiteSpace: 'break-spaces'}}>
                      <Value small thin>
                        {loanType}
                      </Value>
                    </Flex>
                  ) : null}
                </Box>
                <Box ml={48}>
                  <Label>Interest rate as low as</Label>
                  {!disabled ? (
                    <Flex>
                      <Value>
                        {formatNumber(positionedInterestRate, true)}%
                      </Value>
                      <Value ml="14px">{'APR'}</Value>
                    </Flex>
                  ) : null}
                </Box>
              </StyledFlex>
              {!disabled ? (
                <AlertWithIcon variant="info" mt={24} alignItems="center">
                  Actual rate is calculated when you apply with the Lender.
                  Learn more about{' '}
                  <Link blank href={LENDER_RATES_URL}>
                    how lenders set rates for RenoFi loans
                  </Link>
                  .
                </AlertWithIcon>
              ) : null}
            </StyledBox>
            <Box>
              {isTpo ? (
                <Text
                  fontSize={24}
                  lineHeight="24px"
                  fontWeight="bold"
                  color={basic80}>
                  What&apos;s next?
                </Text>
              ) : null}
              {disabled && !isTpo ? (
                <Text fontSize={18} lineHeight="24px" fontWeight="bold">
                  How to apply
                </Text>
              ) : null}
              {!disabled && !isTpo ? (
                <>
                  <NonTpoInformation
                    lender={lender}
                    applyUrl={lender?.applyUrl}
                    isDisabled={disabled}
                    onApplyOnline={() => openTab(lender?.applyUrl)}
                    appraisedValue={appraisedValue}
                  />
                  {lender?.contactInformation1 ||
                  lender?.contactInformation2 ? (
                    <Contacts lender={lender} />
                  ) : null}
                </>
              ) : null}
              {!disabled && isTpo ? (
                <>
                  <Text mt={40}>
                    {lenderName} will be sending you initial disclosures to
                    sign. RenoFi will continue to pay close attention to the
                    progress of your loan file and if you have questions
                    specific to {lenderShortName} the primary {contactText}{' '}
                    shown below:
                  </Text>
                  {lender?.contactInformation1 ||
                  lender?.contactInformation2 ? (
                    <ContactInformation lender={lender} isTpo={isTpo} />
                  ) : null}
                  <Text mt={20}>
                    If you have any questions along the way we are here to help
                    and we will be in contact with you once the file is formally
                    underwritten by {lenderName}.
                  </Text>
                  <Text mt={20}>
                    <p>Best,</p>
                    <strong>RenoFi Operations Team</strong>
                    <Link
                      css={{display: 'block'}}
                      href="mailto:OperationsTeam@RenoFi.com">
                      OperationsTeam@RenoFi.com
                    </Link>
                  </Text>
                </>
              ) : null}
            </Box>
          </Wrapper>
        </Card>
      ) : null}
      {hasSummaryDetails && !isNormalView ? (
        <Card py={24}>
          <SummaryOfInformation
            lenderName={lenderName}
            createdDate={createdDate}
            productDetails={productDetails}
            isTpo={isTpo}
          />
        </Card>
      ) : null}
      {isNormalView ? <Help /> : null}
    </Container>
  );
};

export default Lender;
