import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {ListBox, ListItem, IconBox, Label} from 'lib/styles/ListBox.styles';

import Accept from '@renofi/icons/src/Accept';
import {Text} from '@renofi/components-internal';
import {sendEvent} from '@renofi/analytics';
import {propertyStructureTypes} from '@renofi/utilities/src/enumTypes';

import SingleFamilyHome from './icons/propertyTypes/SingleFamilyHome';
import Townhouse from './icons/propertyTypes/Townhouse';
import RowHome from './icons/propertyTypes/RowHome';
import Condo from './icons/propertyTypes/Condo';
import MultiUnitHome from './icons/propertyTypes/MultiUnitHome';
import {Wrapper} from './styled';

const ICON_MAPPER = {
  single_family: <SingleFamilyHome />,
  townhouse: <Townhouse />,
  row_home: <RowHome />,
  fnma_warrantable_condo: <Condo />,
  multi_unit: <MultiUnitHome />,
};

const propertyTypes = Object.keys(propertyStructureTypes).map((key) => {
  return {
    value: key,
    label: propertyStructureTypes[key],
    icon: ICON_MAPPER[key] || '',
  };
});

const TypeOfHome = ({info, update}) => {
  const onChange = (value) => {
    sendEvent('Secure/Onboarding-User-PropertyType-Selected');
    update({propertyStructureType: value});
  };

  useEffect(() => {
    sendEvent('Secure/Onboarding-User-PropertyType-Presented');
  }, []);

  return (
    <Wrapper>
      <Text mb={4} fontSize={24}>
        What type of home are you renovating?
      </Text>

      <ListBox>
        {propertyTypes.map(({icon, label, value}) => (
          <ListItem
            key={value}
            active={info?.propertyStructureType === value}
            onClick={() => onChange(value)}>
            <IconBox>
              {info?.propertyStructureType === value ? (
                <Accept color="#00A0FF" />
              ) : (
                icon
              )}
            </IconBox>
            <Label>{label}</Label>
          </ListItem>
        ))}
      </ListBox>
    </Wrapper>
  );
};

TypeOfHome.propTypes = {
  info: PropTypes.shape({
    propertyStructureType: PropTypes.string,
  }),
  update: PropTypes.func.isRequired,
};

export default TypeOfHome;
