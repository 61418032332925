import {useContext, useMemo} from 'react';

import {find, propEq, propOr} from 'ramda';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {TASK_STATUSES} from 'modules/project';

import {useToggledBorrowerProject, useProjectTasks} from '@renofi/graphql';
import {sendEvent} from '@renofi/analytics';

import getTaskStatus from '../getTaskStatus';

const CAN_CLOSE_STATUSES = ['success', 'complete'];

const useCurrentTask = ({taskId, facet}) => {
  const {allTasks = [], refetchProjectTasks} = useContext(ProjectTasksContext);
  const {project} = useToggledBorrowerProject();
  const projectId = propOr(null, 'id', project);

  const {loading} = useProjectTasks({
    lazy: true,
    notifyOnNetworkStatusChange: true,
  });

  const task = useMemo(
    () => find(propEq('id', taskId), allTasks),
    [taskId, JSON.stringify(allTasks)],
  );

  const {borrowerFinishedAt, status, taskType} = task || {};

  const variant = getTaskStatus(task || {});
  const closedStatuses = ['warning', ...CAN_CLOSE_STATUSES];
  const isBorrowerFinished = Boolean(borrowerFinishedAt);
  const isCompleted = status === TASK_STATUSES.completed;
  const isOutstanding = status === TASK_STATUSES.outstanding;
  const isProcessing = status === TASK_STATUSES.processing;

  const sendTaskEvent = (eventName, extraParams = {}) => {
    const {lender, loanType} = project || {};
    const {name: lenderName, tpoEnabled} = lender || {};
    const params = {
      taskId,
      taskType,
      lenderName,
      loanType,
      tpoEnabled,
      ...extraParams,
    };

    sendEvent(eventName, params);
  };

  return {
    closedStatuses,
    facet,
    isBorrowerFinished,
    isCompleted,
    isOutstanding,
    isProcessing,
    loading,
    projectId,
    status,
    task,
    tasks: allTasks,
    variant,
    refetchProjectTasks,
    sendEvent: sendTaskEvent,
  };
};

export default useCurrentTask;
