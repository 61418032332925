import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';

import Box from '../../../Box';
import Flex from '../../../Flex';

export const AndSymbol = styled(Box)({
  opacity: 0.2,
  fontSize: 16,
});

export const Wrapper = styled(Flex)(({hasLenderLogo}) => {
  return {
    img: {
      width: hasLenderLogo ? 100 : 'auto',
      height: 'auto',
    },
    svg: {
      width: hasLenderLogo ? 100 : 134,
      height: 38,
    },
    [maxSmall]: {
      svg: {
        width: 86,
        height: 23,
      },
    },
  };
});
