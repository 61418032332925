export const SUBJECT_PROPERTY_INPUT_PROPS = [
  'city',
  'coborrowerOwned',
  'county',
  'existingMortgageLoan',
  'homeownerMonthlyInsurance',
  'homeownersAssociationFee',
  'homeownersAssociationFeesExist',
  'homeownersAssociationFeesIncluded',
  'id',
  'mortgageBalance',
  'mortgageMonthlyInsurance',
  'mortgageMonthlyPayment',
  'otherMonthlyExpenses',
  'primaryBorrowerOwned',
  'propertyMonthlyTaxes',
  'propertyStructureType',
  'propertyUseType',
  'state',
  'streetAddressOne',
  'streetAddressTwo',
  'taxAndInsuranceIncluded',
  'yearPurchased',
  'zipCode',
];
