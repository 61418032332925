import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {isMobile} from '@renofi/theme/src/breakpoints';
import {sendEvent} from '@renofi/analytics';

import {STEP_MAPPER} from '../constants';

import BaseContent from './BaseContent';
import {getPropertyDetails} from './utils';

const PropertyTrust = ({place, setStep, update}) => {
  const onAccept = async () => {
    await update({
      propertyOwnedByTrust: true,
    });
    setStep(STEP_MAPPER.PropertyTrustWarning);
    sendEvent('Secure/Onboarding-User-Trust-Selected');
  };

  const onDecline = async () => {
    await update({
      propertyOwnedByTrust: false,
    });
    setStep(STEP_MAPPER.PropertyTitle);
    sendEvent('Secure/Onboarding-User-Trust-Selected');
  };

  const details = getPropertyDetails(place);

  useEffect(() => {
    sendEvent('Secure/Onboarding-User-Trust-Presented');
  }, []);

  return (
    <BaseContent
      mainContent="Is the property owned by a trust?"
      sideContent={details}
      onAccept={onAccept}
      onDecline={onDecline}
      sideContentCss={{textAlign: isMobile() ? 'center' : 'left'}}
    />
  );
};

PropertyTrust.propTypes = {
  place: PropTypes.object,
  setStep: PropTypes.func,
  update: PropTypes.func,
};

export default PropertyTrust;
