import capitalize from '../capitalize';

import INTEREST_TYPES from './interestTypes';

export default (rate, interestType, defaultValue = '-') => {
  if (!rate) {
    return defaultValue;
  }

  let text = `${rate}% APR`;

  if (interestType === INTEREST_TYPES.FIXED) {
    const capitalized = capitalize(interestType);
    text = text.concat(`, ${capitalized}`);
  }

  return text;
};
