import React, {useState, useEffect} from 'react';

import {isEmpty, propOr} from 'ramda';
import PropTypes from 'prop-types';
import {STATUS_OUTSTANDING} from 'modules/project/components/Project/constants';
import {useCurrentTask} from 'modules/project/hooks';

import {
  GET_PROJECT_TASKS,
  useMarkTaskDataAsReadyForProcessing,
  useMarkTaskFinishedByBorrower,
} from '@renofi/graphql';
import {Wizard} from '@renofi/components-internal';
import {useNotifications} from '@renofi/utilities/src/hooks';

import {updateGroupedStepsItems, updateStepsItems} from '../utils';
import {
  STEP_TYPES,
  CO_BORROWER_STEP_TYPES,
  INITIAL_GROUPED_STEPS,
  INITIAL_STEPS,
  SUCCESS_NOTIFICATION,
  FINISHED_EVENT,
  GROUPED_STEPS,
} from '../constants';

const {CO_UPLOAD_ID, UPLOAD_ID, ID_TYPE, CO_ID_TYPE, CO_ID_DETAILS} =
  CO_BORROWER_STEP_TYPES;

const WizardComponent = ({
  children,
  project,
  taskId,
  config,
  currentStep,
  setCurrentStep,
  hasCoBorrower,
  documents,
  onSubmit,
  status: taskStatus,
  wizardIsLoading,
  wizardIdTypes,
  nextButtonDisableState,
  isIntroPage,
  onCloseWizard,
  onClose,
  govIdDetails,
}) => {
  const projectId = propOr(null, 'id', project);
  //Hooks
  const [steps, setSteps] = useState(
    hasCoBorrower ? INITIAL_GROUPED_STEPS : INITIAL_STEPS,
  );
  const {sendEvent} = useCurrentTask({taskId});
  const {addNotification} = useNotifications();
  const {markTaskFinishedByBorrower} = useMarkTaskFinishedByBorrower();
  const {markTaskDataAsReadyForProcessing} =
    useMarkTaskDataAsReadyForProcessing({
      refetchQueries: [
        {
          query: GET_PROJECT_TASKS,
          variables: {projectId},
        },
      ],
    });

  //Constants
  const {isFirstStep, isFinalStep, finalLabel, nextLabel} = config;
  const stepsCount = hasCoBorrower ? 6 : 3;
  const isOutstandingStatus = taskStatus === STATUS_OUTSTANDING;
  const isFinishDisabled =
    [CO_UPLOAD_ID, UPLOAD_ID].includes(currentStep) && isEmpty(documents);

  useEffect(() => {
    const getUpdatedSteps = hasCoBorrower
      ? updateGroupedStepsItems
      : updateStepsItems;
    const updatedSteps = getUpdatedSteps(
      currentStep,
      wizardIdTypes,
      taskStatus,
      govIdDetails,
      project,
    );
    setSteps([...updatedSteps]);
  }, [currentStep, wizardIdTypes, govIdDetails]);

  //Handlers
  const onClickNext = () => {
    const isIdDetailsStep = [STEP_TYPES.ID_DETAILS, CO_ID_DETAILS].includes(
      currentStep,
    );

    if (isIdDetailsStep) {
      onSubmit();
      return;
    }

    if (stepsCount) {
      setCurrentStep(currentStep + 1);
    }
  };

  const onClickPrev = () => {
    if (currentStep === 1) {
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const onSelectStep = (id) => {
    if (isOutstandingStatus) {
      addNotification({
        variant: 'danger',
        content:
          'Please complete the current step before progressing to another.',
        type: 'snackbar',
      });
      return;
    }

    const {PRIMARY_BORROWER_STEP, CO_BORROWER_STEP} = GROUPED_STEPS;

    let newCurrentStep = id;

    if (id === PRIMARY_BORROWER_STEP) {
      newCurrentStep = ID_TYPE;
    }

    if (id === CO_BORROWER_STEP) {
      newCurrentStep = CO_ID_TYPE;
    }

    setCurrentStep(newCurrentStep);
  };

  const onFinish = () => {
    sendEvent('Secure/Task-Finished', FINISHED_EVENT);
    markTaskFinishedByBorrower({variables: {taskId}});
    markTaskDataAsReadyForProcessing({variables: {taskId}});
    onClose();
    addNotification(SUCCESS_NOTIFICATION);
  };

  return (
    <Wizard
      show
      final={isFinalStep}
      loading={wizardIsLoading}
      finalButtonVariant="primary"
      withBackOnFinal
      steps={steps}
      disabled={nextButtonDisableState || isFinishDisabled}
      header="Upload a government issued ID"
      onNext={onClickNext}
      onPrev={onClickPrev}
      onSelectStep={onSelectStep}
      onFinish={onFinish}
      finalLabel={finalLabel}
      nextLabel={nextLabel || 'next'}
      onStart={() => {}}
      hidePrev={isFirstStep}
      role="government-id-wizard"
      showNext={!isIntroPage}
      onClose={onCloseWizard}
      clickBackdropToClose>
      {children}
    </Wizard>
  );
};

WizardComponent.propTypes = {
  children: PropTypes.node,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  taskId: PropTypes.string,
  config: PropTypes.shape({
    isFirstStep: PropTypes.bool,
    isFinalStep: PropTypes.bool,
    finalLabel: PropTypes.string,
    nextLabel: PropTypes.string,
  }),
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  hasCoBorrower: PropTypes.bool,
  documents: PropTypes.array,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  wizardIsLoading: PropTypes.bool,
  wizardIdTypes: PropTypes.array,
  nextButtonDisableState: PropTypes.bool,
  isIntroPage: PropTypes.bool,
  onCloseWizard: PropTypes.func,
  onClose: PropTypes.func,
  govIdDetails: PropTypes.array,
};

export default WizardComponent;
