import {compose, withHandlers, withState} from '@renofi/recompose';
import {
  withChangeSmsNumber,
  withConfirmSmsNumber,
  withUser,
} from '@renofi/graphql';

import Component from './Component';

export default compose(
  withUser(),
  withState('verify', 'setVerify', false),
  withState('loading', 'setLoading', false),
  withState('status', 'setStatus', null),
  withState(
    'phoneNumber',
    'setPhoneNumber',
    ({phoneNumber, user}) => phoneNumber || user?.phoneNumber,
  ),
  withChangeSmsNumber(),
  withConfirmSmsNumber(),
  withHandlers({
    onSendVerification:
      ({phoneNumber, changeSmsNumber, setLoading, setVerify}) =>
      async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
          await changeSmsNumber(phoneNumber);
          setVerify(true);
        } finally {
          setLoading(false);
        }
      },
    onChangePhoneNumber:
      ({setPhoneNumber}) =>
      (value) => {
        setPhoneNumber(value);
      },
    onGoBack:
      ({setVerify}) =>
      () => {
        setVerify(false);
      },
    onSubmitCode:
      ({phoneNumber: smsNumber, confirmSmsNumber, setLoading, setStatus}) =>
      async (code) => {
        setLoading(true);
        try {
          await confirmSmsNumber({smsNumber, code});
          setStatus('success');
        } catch (error) {
          console.error(error);
          setStatus('failure');
        } finally {
          setLoading(false);
        }
      },
  }),
)(Component);
